import {useState} from 'react';
import {Button, Form, Alert} from 'react-bootstrap';

// URL: profitableonlinebusinessideas.com/2CJ5Xkyvl3

export default function BoghBuyForm(){
    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [success, setSuccess] = useState(false);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        setSuccess(true);

        const pkg = {
        fname: fname,
        lname: lname,
        email: email,
        phone: phone,
        }
        
        fetch('/boghbuyopt', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-type': 'application/json',
        },
        body: JSON.stringify(pkg)
        })
        .then((res) => res.json())
        .then((data) => {
        console.log("Success", data)
        });

        window.open("https://alkalineionizedantioxidantwaterhealthbenefits.com/thank-you.html");
    }

    return(
        <div className="container-fluid opt floydsellform">
                <div className="row" id="opt">
                    <div className="col-12" id="formstyler">
                        <Form onSubmit={handleSubmit}>
                            <h3>Fill Out This Form to Get Information on Available Properties Around San Bernardino!</h3>
                            <div className="row">
                                <div className="col-md-6 col-sm-12 optform">
                                    <Form.Group>
                                        <Form.Control required type="text" placeholder="First Name" onChange={(e) => setFname(e.target.value)} />
                                    </Form.Group>
                                </div>
                                <div className="col-md-6 col-sm-12 optform">
                                    <Form.Group>
                                        <Form.Control required type="text" placeholder="Last Name" onChange={(e) => setLname(e.target.value)} />
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-sm-12 optform">
                                    <Form.Group>
                                        <Form.Control required type="email" placeholder="youremail@provider.com" onChange={(e) => setEmail(e.target.value)} />
                                    </Form.Group>
                                </div>
                                <div className="col-md-6 col-sm-12 optform">
                                    <Form.Group>
                                        <Form.Control required type="text" placeholder="Phone: (XXX)XXX-XXXX" onChange={(e) => setPhone(e.target.value)} />
                                    </Form.Group>
                                </div>
                            </div>
                            {!success &&
                                <Button variant="secondary" type="submit">Get Started Today!</Button>
                            }
                            {success &&
                                <Button variant="success">Success!</Button>
                            }
                        </Form>
                    </div>
                </div>
        </div>
    )
}