import {useState} from 'react';
import {Button, Form, Alert} from 'react-bootstrap';

// URL: profitableonlinebusinessideas.com/KgNnnY53F2

export default function KlipForm(){
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [success, setSuccess] = useState(false);
    const [time, setTime] = useState("");
    const [zip, setZip] = useState("");
    const [msg, setMsg] = useState("");
    const [math, setMath] = useState("");
    const [mathcheck, setCheck] = useState("3331");

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        setSuccess(true);

        const pkg = {
        name: name,
        email: email,
        phone: phone,
        time: time,
        zip: zip,
        msg: msg
        }
        
        fetch('/klipopt', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-type': 'application/json',
        },
        body: JSON.stringify(pkg)
        })
        .then((res) => res.json())
        .then((data) => {
        console.log("Success", data)
        });

        window.open("https://powur.com/keith.klipstein2/", "_blank");
    }

    return(
        <div className="container-fluid opt solarform">
                <div className="row" id="opt">
                    <div className="col-12" id="formstyler">
                        <Form onSubmit={handleSubmit}>
                            <h2>Enter the Information Below for a Free No Obligation Solar Quote and Find Out How Much You Can Save Today!</h2>
                            <div className="row">
                                <div className="col-md-4 col-sm-12 optform">
                                    <Form.Group>
                                        <Form.Control required type="text" placeholder="Your Name" onChange={(e) => setName(e.target.value)} />
                                    </Form.Group>
                                </div>
                                <div className="col-md-5 col-sm-12 optform">
                                    <Form.Group>
                                        <Form.Control required type="email" placeholder="youremail@provider.com" onChange={(e) => setEmail(e.target.value)} />
                                    </Form.Group>
                                </div>
                                <div className="col-md-3 col-sm-12 optform">
                                    <Form.Group>
                                        <Form.Control required type="text" placeholder="Phone: (XXX)XXX-XXXX" onChange={(e) => setPhone(e.target.value)} />
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-2 col-sm-1"></div>
                                <div className="col-md-8 col-sm-10 optform">
                                    <Form.Group>
                                        <Form.Control required type="text" placeholder="Average Monthly Electric Bill" onChange={(e) => setTime(e.target.value)} />
                                    </Form.Group>
                                </div>
                                <div className="col-md-2 col-sm-1"></div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 col-sm-1"></div>
                                <div className="col-md-4 col-sm-10">
                                    <Form.Group>
                                        <Form.Control required type="text" placeholder="Type 3331 to Continue" onChange={(e) => setMath(e.target.value)} />
                                    </Form.Group>
                                </div>
                                <div className="col-md-4 col-sm-1"></div>
                            </div>
                            {math != mathcheck &&
                                <Alert variant="primary">Complete the Form to Continue</Alert>
                            }
                            {!success && math == mathcheck &&
                                <Button variant="primary" type="submit">Get Started Today!</Button>
                            }
                            {success &&
                                <Button variant="success">Success!</Button>
                            }
                        </Form>
                    </div>
                </div>
        </div>
    )
}