import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from 'react-router-dom';
import TharForm from './components/tharaldsen';
import DigiForm from './components/digi';
import TurnqForm from './components/turnquist';
import HinesForm from './components/hines';
import DeliForm from './components/delicana';
import PetersForm from './components/peters';
import GriffForm from './components/griffiths';
import CuppsForm from './components/cupps';
import SmithForm from './components/smith';
import KerewForm from './components/kerew';
import GStewForm from './components/gstewart';
import RicciForm from './components/ricci';
import MitchForm from './components/mitchell';
import SilverForm from './components/silver';
import CiriForm from './components/ciriaco';
import SimonsForm from './components/simons';
import TuttleForm from './components/tuttle';
import HerrForm from './components/herrform';
import YoungForm from './components/young';
import DemoForm from './components/demo';
import TurnkeyForm from './components/turnkey';
import GramsForm from './components/grams';
import GibsonForm from './components/gibson';
import ReamsForm from './components/reams';
import KollForm from './components/koll';
import WilliamsForm from './components/williams';
import MolForm from './components/molatore';
import FaurotForm from './components/faurot';
import LeikForm from './components/leiker';
import AcunaForm from './components/acuna';
import OwenForm from './components/owen';
import BoydForm from './components/boyd';
import DavisForm from './components/davis';
import RomoForm from './components/romo';
import ClarkeForm from './components/clarke';
import DavisCTSForm from './components/daviscts';
import DreiForm from './components/dreidger';
import DineForm from './components/dinerman';
import ArielForm from './components/ariel';
import GibbsForm from './components/gibbs';
import SimpForm from './components/simpson';
import JohnForm from './components/johnson';
import PatterForm from './components/patterson';
import NichForm from './components/nichols';
import KinsForm from './components/kins';
import CoachForm from './components/coaching';
import CryptForm from './components/crypto';
import MartForm from './components/martu';
import NdForm from './components/ndupu';
import WilsonForm from './components/wilson';
import MarshForm from './components/marshall';
import KarlaForm from './components/karla';
import RedoxForm from './components/redox';
import RenoForm from './components/reno';
import LippeForm from './components/lippe';
import HostForm from './components/host';
import GrossForm from './components/grosso';
import DarlForm from './components/darl';
import HopForm from './components/hopkins';
import MaatForm from './components/maat';
import DerrForm from './components/derr';
import GriffinForm from './components/griffin';
import KantForm from './components/kantwerk';
import GoodForm from './components/goodman';
import TherForm from './components/theroux';
import KielForm from './components/kiel';
import McForm from './components/mcneal';
import EspForm from './components/espinosa';
import BrewForm from './components/brewer';
import GruForm from './components/gruner';
import BeckForm from './components/becker';
import IsleForm from './components/isle';
import JSForm from './components/jsmith';
import FilForm from './components/filichia';
import DjohnForm from './components/djohn';
import FranForm from './components/francis';
import LimaForm from './components/lima';
import RussForm from './components/russell';
import MimbsForm from './components/mimbs';
import BellForm from './components/bell';
import MakeForm from './components/makecash';
import EWillForm from './components/ewill';
import WintForm from './components/wint';
import HollForm from './components/holloway';
import CorbForm from './components/corb';
import BrazForm from './components/brazil';
import CollinsForm from './components/collins';
import SellForm from './components/sellers';
import BuyForm from './components/buyers';
import WolfeForm from './components/wolfe';
import MJBuyForm from './components/mjacksonbuy';
import MJSellForm from './components/mjacksonsell';
import WattSellForm from './components/wattonsell';
import WattBuyForm from './components/wattonbuy';
import CastBuyForm from './components/castbuy';
import CastSellForm from './components/castsell';
import PerezForm from './components/perez';
import WinBuyForm from './components/winbuy';
import WinSellForm from './components/winsell';
import KernBuyForm from './components/kernsbuy';
import KernSellForm from './components/kernssell';
import MeierForm from './components/meier';
import RHBuyForm from './components/rhollobuy';
import RHSellForm from './components/rhollosell';
import GodBuyForm from './components/godbuy';
import GodSellForm from './components/godsell';
import BogBuyForm from './components/bogbuy';
import BogSellForm from './components/bogsell';
import EdForm from './components/edwards';
import RyneForm from './components/ryne';
import GarnForm from './components/garner';
import DsmithForm from './components/dsmithre';
import BauForm from './components/bauman';
import NuckBuyForm from './components/nuckbuy';
import NuckSellForm from './components/nucksell';
import UmanSellForm from './components/umansky';
import OroSellForm from './components/orosell';
import WhiteSellForm from './components/whitesell';
import StaSellForm from './components/stasell';
import FinnForm from './components/finn';
import ParryForm from './components/parry';
import ArdForm from './components/ardon';
import MapForm from './components/maples';
import KlipForm from './components/klip';
import EvansBuyForm from './components/evans';
import WorBuyForm from './components/worbuy';
import WorSellForm from './components/worsell';
import WeissSellForm from './components/weisssell';
import WeissBuyForm from './components/weissbuy';
import NevBuyForm from './components/nevbuy';
import NevSellForm from './components/nevsell';
import FamBuyForm from './components/fambuy';
import FamSellForm from './components/famsell';
import BuskSellForm from './components/busk';
import RohrBuyForm from './components/rohrbuy';
import RohrSellForm from './components/rohrsell';
import QuigBuyForm from './components/quigbuy';
import QuigSellForm from './components/quigsell';
import MapWaterForm from './components/mapleswater';
import FloydBuyForm from './components/floydbuy';
import FloydSellForm from './components/floydsell';
import LongSellForm from './components/longsell';
import LongBuyForm from './components/longbuy';
import LJackBuyForm from './components/ljackbuy';
import LJackSellForm from './components/ljacksell';
import MairSellForm from './components/mairsell';
import WattsBuyForm from './components/wattsbuy';
import WattsSellForm from './components/wattssell';
import StormForm from './components/stormer';
import LopezBuyForm from './components/lopezbuy';
import LopezSellForm from './components/lopezsell';
import WindhorstSellForm from './components/windhorst';
import DemoBuyForm from './components/rebuydemo';
import DemoSellForm from './components/reselldemo';
import HleeBuyForm from './components/hleebuy';
import HleeSellForm from './components/hleesell';
import StipesBuyForm from './components/stipesbuy';
import StipesSellForm from './components/stipessell';
import MonteForm from './components/monte';
import BoghBuyForm from './components/boghossbuy';
import BoghSellForm from './components/boghosssell';
import ZurbaForm from './components/zurbaopt';
import KulgBuyForm from './components/kulgbuy';
import KulgSellForm from './components/kulgsell';
import GillNForm from './components/gillneumi';
import LinfForm from './components/linfoot';
import CJBBuyForm from './components/cjbrownbuy';
import CJBSellForm from './components/cjbrownsell';
import HammSellForm from './components/hammsell';
import HammBuyForm from './components/hammbuy';
import MattBuyForm from './components/mattbuy';
import EmaalBuyForm from './components/emaal';
import CarvForm from './components/carvalho';
import MattProbBuyForm from './components/mattprob';
import RyanBuyForm from './components/ryanbuy';
import RyanSellForm from './components/ryansell';
import ConnorBuyForm from './components/connorbuy';
import ConnorSellForm from './components/connorsell';
import MilesBuyForm from './components/milesbuy';
import MilesSellForm from './components/milessell';
import ManfrediSellForm from './components/manfredi';
import HultForm from './components/hultberg';
import MSmithBuyForm from './components/msmithbuy';
import MSmithSellForm from './components/msmithsell';
import SormBuyForm from './components/sormbuy';
import SormSellForm from './components/sormsell';
import CRichBuyForm from './components/crichbuy';
import CRichSellForm from './components/crichsell';
import Home from './Home';
import './App.css';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/RpUcUQ7g3C">
          <SormBuyForm />
        </Route>
        <Route path="/4Q5eLiJGho">
          <SormSellForm />
        </Route>
        <Route path="/OVJo9j69HZ">
          <CRichBuyForm />
        </Route>
        <Route path="/93LrrlYjRe">
          <CRichSellForm />
        </Route>
        <Route path="/rHFJOoBIDg">
          <MSmithBuyForm />
        </Route>
        <Route path="/ZhvauxucjA">
          <MSmithSellForm />
        </Route>
        <Route path="/2k2sAVkpL9">
          <HultForm />
        </Route>
        <Route path="/ZF8UHQAqfS">
          <ManfrediSellForm />
        </Route>
        <Route path="/8hdaG0ID5r">
          <MilesBuyForm />
        </Route>
        <Route path="/RJW4rwJ9hl">
          <MilesSellForm />
        </Route>
        <Route path="/tf3hLMFfLz">
          <ConnorBuyForm />
        </Route>
        <Route path="/JGDT9v3bFy">
          <ConnorSellForm />
        </Route>
        <Route path="/Sp5gDFCRZv">
          <RyanBuyForm />
        </Route>
        <Route path="/8Qtfzdmq49">
          <RyanSellForm />
        </Route>
        <Route path="/e1bbZDZ6BU">
          <MattProbBuyForm />
        </Route>
        <Route path="/ZWYEjBdo0M">
          <CarvForm />
        </Route>
        <Route path="/ry00RnrbOp">
          <EmaalBuyForm />
        </Route>
        <Route path="/OLdsCQv7CW">
          <MattBuyForm />
        </Route>
        <Route path="/a0GSHPizjN">
          <HammBuyForm />
        </Route>
        <Route path="/e0MURa0nIT">
          <HammSellForm />
        </Route>
        <Route path="/N6SRIo3EtC">
          <CJBBuyForm />
        </Route>
        <Route path="/zx9rsvx9w0">
          <CJBSellForm />
        </Route>
        <Route path="/B8llsaP16M">
          <LinfForm />
        </Route>
        <Route path="/lSUwqCxUSb">
          <GillNForm />
        </Route>
        <Route path="/mu7RIWTySI">
          <KulgBuyForm />
        </Route>
        <Route path="/QYiCkxf3Qr">
          <KulgSellForm />
        </Route>
        <Route path="/kmkSOfvNlB">
          <ZurbaForm />
        </Route>
        <Route path="/2CJ5Xkyvl3">
          <BoghBuyForm />
        </Route>
        <Route path="/uXjF7sHTUk">
          <BoghSellForm />
        </Route>
        <Route path="/YsQ7z8JruM">
          <MonteForm />
        </Route>
        <Route path="/GjGGFvrhIk">
          <StipesBuyForm />
        </Route>
        <Route path="/uYB0lwVHyU">
          <StipesSellForm />
        </Route>
        <Route path="/mS8iYbqg9n">
          <HleeBuyForm />
        </Route>
        <Route path="/1kYC09lXqL">
          <HleeSellForm />
        </Route>
        <Route path="/VZ462poql3">
          <DemoBuyForm />
        </Route>
        <Route path="/GjinAGA7kC">
          <DemoSellForm />
        </Route>
        <Route path="/BfibFJnEsP">
          <WindhorstSellForm />
        </Route>
        <Route path="/zHeO5PG8TS">
          <LopezBuyForm />
        </Route>
        <Route path="/SYwj4v0PCe">
          <LopezSellForm />
        </Route>
        <Route path="/K11vEvUaxH">
          <StormForm />
        </Route>
        <Route path="/opyjsPT86Q">
          <WattsSellForm />
        </Route>
        <Route path="/ILOF1sNeVB">
          <WattsBuyForm />
        </Route>
        <Route path="/PBRacOGWIZ">
          <MairSellForm />
        </Route>
        <Route path="/KlvFzowSBv">
          <LJackBuyForm />
        </Route>
        <Route path="/J3YVsuM33a">
          <LJackSellForm />
        </Route>
        <Route path="/9onwjZAnmE">
          <LongBuyForm />
        </Route>
        <Route path="/pkVi6YnN4Y">
          <LongSellForm />
        </Route>
        <Route path="/ZurulkX9Ch">
          <FloydSellForm />
        </Route>
        <Route path="/oZG7RDaS6j">
          <FloydBuyForm />
        </Route>
        <Route path="/C9r68DUYWd">
          <MapWaterForm />
        </Route>
        <Route path="/ETbc4fIAbB">
          <QuigBuyForm />
        </Route>
        <Route path="/Vypich6gDt">
          <QuigSellForm />
        </Route>
        <Route path="/F47kXPVe5o">
          <RohrBuyForm />
        </Route>
        <Route path="/4qN65uhuzr">
          <RohrSellForm />
        </Route>
        <Route path="/s1a9RhMWU8">
          <FamBuyForm />  
        </Route>
        <Route path="/h7XQzimZSm">
          <FamSellForm />
        </Route>
        <Route path="/2amr3Q3GpR">
          <BuskSellForm />
        </Route>
        <Route path="/iXnWi7hPET">
          <NevBuyForm />
        </Route>
        <Route path="/ujxNmFzche">
          <NevSellForm />
        </Route>
        <Route path="/FtQDbxWAkc">
          <WeissSellForm />
        </Route>
        <Route path="/xYbb7ukfRJ">
          <WeissBuyForm />
        </Route>
        <Route path="/7AjWW3BV3l">
          <WorBuyForm />
        </Route>
        <Route path="/OhDfjpnkCY">
          <WorSellForm />
        </Route>
        <Route path="/TQOgAtcM0L">
          <EvansBuyForm />
        </Route>
        <Route path="/KgNnnY53F2">
          <KlipForm />
        </Route>
        <Route path="/dytGgQTUNz">
          <MapForm />
        </Route>
        <Route path="/89Lk7x1RDQ">
          <ArdForm />
        </Route>
        <Route path="/NwFwt9rOl1">
          <ParryForm />
        </Route>
        <Route path="/SC5BoXPs5E">
          <FinnForm />
        </Route>
        <Route path="/HJ67bVBzzw">
          <StaSellForm />
        </Route>
        <Route path="/o8wOnLrUFF">
          <WhiteSellForm />
        </Route>
        <Route path="/atVAnmbp31">
          <OroSellForm />
        </Route>
        <Route path="/CvOzbYw9yK">
          <UmanSellForm />
        </Route>
        <Route path="/2DQYml9ScO">
          <NuckBuyForm />
        </Route>
        <Route path="/OadFITG0mN">
          <NuckSellForm />
        </Route>
        <Route path="/a0GxPH0dGR">
          <BauForm />
        </Route>
        <Route path="/9KLWAdFgS5">
          <DsmithForm />
        </Route>
        <Route path="/La2jc5n8IN">
          <GarnForm />
        </Route>
        <Route path="/QRCLHttMvD">
          <RyneForm />
        </Route>
        <Route path="/bniZfVWnzX">
          <EdForm />
        </Route>
        <Route path="/BkRfWMJdZX">
          <BogBuyForm />
        </Route>
        <Route path="/den32JwHzY">
          <BogSellForm />
        </Route>
        <Route path="/WL6YN4OXyf">
          <GodBuyForm />
        </Route>
        <Route path="/QpPqCjRrLH">
          <GodSellForm />
        </Route>
        <Route path="/a0rqaUDfuM">
          <RHBuyForm />
        </Route>
        <Route path="/BTiH53Ii0h">
          <RHSellForm />
        </Route>
        <Route path="/CzhEPjZuAb">
          <MeierForm />
        </Route>
        <Route path="/uT2y37fq4C">
          <KernBuyForm />
        </Route>
        <Route path="/g1p6uNIHvp">
          <KernSellForm />
        </Route>
        <Route path="/QbLIXeOd61">
          <WinBuyForm />
        </Route>
        <Route path="/amXxey9kR0">
          <WinSellForm />
        </Route>
        <Route path="/TOtuDljvoj">
          <PerezForm />
        </Route>
        <Route path="/Zv5hFB5grK">
          <CastBuyForm />
        </Route>
        <Route path="/3WVhIkZyfi">
          <CastSellForm />
        </Route>
        <Route path="/KyR5jJ0z3o">
          <WattSellForm />
        </Route>
        <Route path="/y3MV7X66UN">
          <WattBuyForm />
        </Route>
        <Route path="/kgIGHnJTLN">
          <MJBuyForm />
        </Route>
        <Route path="/wO2cvJliS4">
          <MJSellForm />
        </Route>
        <Route path="/k0aTWzMusY">
          <WolfeForm />
        </Route>
        <Route path="/2lLjnDqbr9">
          <SellForm />
        </Route>
        <Route path="/Ja1BfCTan1">
          <BuyForm />
        </Route>
        <Route path="/0O6TFqs5YE">
          <CollinsForm />
        </Route>
        <Route path="/VZuKozUgdb">
          <BrazForm />
        </Route>
        <Route path="/TAygQDF6Zk">
          <CorbForm />
        </Route>
        <Route path="/eGzdHHPAps">
          <HollForm />
        </Route>
        <Route path="/svFGoZn5Pn">
          <WintForm />
        </Route>
        <Route path="/9PceT7EPsC">
          <EWillForm />
        </Route>
        <Route path="/1GDNENbr3N">
          <MakeForm />
        </Route>
        <Route path="/N2B2PuPdIH">
          <BellForm />
        </Route>
        <Route path="/4eTCdThZyT">
          <MimbsForm />
        </Route>
        <Route path="/aPldDzoUnJ">
          <RussForm />
        </Route>
        <Route path="/KGIEz3BzFn">
          <LimaForm />
        </Route>
        <Route path="/NORUpeIxOW">
          <DjohnForm />
        </Route>
        <Route path="/FBgOH7wkSR">
          <FranForm />
        </Route>
        <Route path="/HQi7AaF19s">
          <FilForm />
        </Route>
        <Route path="/cCFW0FXqth">
          <IsleForm />
        </Route>
        <Route path="/w5FVWU8RaX">
          <JSForm />
        </Route>
        <Route path="/wAwpkSV598">
          <GruForm />
        </Route>
        <Route path="/YI6f7AHxak">
          <BeckForm />
        </Route>
        <Route path="/CO4wHJ0WWr">
          <BrewForm />
        </Route>
        <Route path="/BcCObPlIAy">
          <EspForm />
        </Route>
        <Route path="/ypHNEYa0QM">
          <McForm />
        </Route>
        <Route path="/4EDttLtApO">
          <TherForm />
        </Route>
        <Route path="/F0F7ClT45C">
          <KielForm />
        </Route>
        <Route path="/PRoKmWj1l5">
          <GoodForm />
        </Route>
        <Route path="/38OGwfuE8y">
          <KantForm />
        </Route>
        <Route path="/WBmL0PGC90">
          <GriffinForm />
        </Route>
        <Route path="/4iioXg1zy0">
          <DerrForm />
        </Route>
        <Route path="/sW7i5ZpWJl">
          <MaatForm />
        </Route>
        <Route path="/wEfnyurIpI">
          <HopForm />
        </Route>
        <Route path="/6QsoS85BIv">
          <DarlForm />
        </Route>
        <Route path="/fjysjmWR51">
          <GrossForm />  
        </Route>
        <Route path="/zDjt2F1MbZ">
          <HostForm />
        </Route>
        <Route path="/GRBWMohi2c">
          <RenoForm />
        </Route>
        <Route path="/QTF8bEIBsz">
          <LippeForm />
        </Route>
        <Route path="/lbWgPEKz7c">
          <RedoxForm />
        </Route>
        <Route path="/1TjvPdTBv8">
          <KarlaForm />
        </Route>
        <Route path="/u9BKa8bNNv">
          <MarshForm />
        </Route>
        <Route path="/di74QXL80e">
          <WilsonForm />
        </Route>
        <Route path="/iwBoWRUhme">
          <NdForm />
        </Route>
        <Route path="/LUiMV0jDLs">
          <MartForm />
        </Route>
        <Route path="/ZVjYxBM9jn">
          <CryptForm />
        </Route>
        <Route path="/ppitO6TzN6">
          <CoachForm />
        </Route>
        <Route path="/wCg6lqzrFS">
          <KinsForm />
        </Route>
        <Route path="/OjNo3ASmdD">
          <NichForm />
        </Route>
        <Route path="/8tKPnCcIKc">
          <PatterForm />
        </Route>
        <Route path="/SuDou1gmyo">
          <JohnForm />
        </Route>
        <Route path="/MaR92iy6yB">
          <GibbsForm />
        </Route>
        <Route path="/cMpAX4m8d8">
          <SimpForm />
        </Route>
        <Route path="/lqipv0dSQ6">
          <ArielForm />
        </Route>
        <Route path="/ETiMmcwfgk">
          <DineForm />
        </Route>
        <Route path="/mdTwWj66iL">
          <DreiForm />
        </Route>
        <Route path="/XpfsLVUKSm">
          <DavisCTSForm />
        </Route>
        <Route path="/sGWdmCxALX">
          <ClarkeForm />
        </Route>
        <Route path="/6lBX7zAcUh">
          <RomoForm />
        </Route>
        <Route path="/fAPatZk8HM">
          <DavisForm />
        </Route>
        <Route path="/0BlYanhv7m">
          <BoydForm />
        </Route>
        <Route path="/tTOrmTlTDH">
          <OwenForm />
        </Route>
        <Route path="/MW7i21tj4j">
          <AcunaForm />
        </Route>
        <Route path="/MfWE6s8pAc">
          <LeikForm />
        </Route>
        <Route path="/VvShvBw3Cv">
          <FaurotForm />
        </Route>
        <Route path="/vceXeDjxhR">
          <KollForm />
        </Route>
        <Route path="/saGzng5NXp">
          <WilliamsForm />
        </Route>
        <Route path="/dGxTibdRDq">
          <MolForm />
        </Route>
        <Route path="/k1c6DjHsCL">
          <ReamsForm />
        </Route>
        <Route path="/4EcAcCjBHK">
          <GramsForm />
        </Route>
        <Route path="/8Jq4C9WlLk">
          <GibsonForm />
        </Route>
        <Route path="/NOH61lTPll">
          <TurnkeyForm />
        </Route>
        <Route path="/LPsTOTNYl8">
          <YoungForm />
        </Route>
        <Route path="/DVN6T1w2SY">
          <DemoForm />
        </Route>
        <Route path="/rlXbB1u6cg">
          <HerrForm />
        </Route>
        <Route path="/TvgSRAFxVe">
          <TuttleForm />
        </Route>
        <Route path="/UIPBqUNbqX">
          <SimonsForm />
        </Route>
        <Route path="/r0ck6ZRfeV">
          <CiriForm />
        </Route>
        <Route path="/LnGglVkH4O">
          <SilverForm />
        </Route>
        <Route path="/Ao67AHjjGG">
          <MitchForm />
        </Route>
        <Route path="/KG7Rk9ixUb">
          <RicciForm />
        </Route>
        <Route path="/elrtlDdSYo">
          <GStewForm />
        </Route>
        <Route path="/28L7JFq9l4">
          <KerewForm />
        </Route>
        <Route path="/TS7uASI7FF">
          <SmithForm />
        </Route>
        <Route path="/VWcgIVmSHM">
          <CuppsForm />
        </Route>
        <Route path="/igy8ZnfZYf">
          <GriffForm />
        </Route>
        <Route path="/h7s36tBAjm">
          <DeliForm />
        </Route>
        <Route path="/lUz7efzUnF">
          <PetersForm />
        </Route>
        <Route path="/BA81pqJoHg">
          <HinesForm />
        </Route>
        <Route path="/kvpsLV16Aq">
          <TurnqForm />
        </Route>
        <Route path="/BD2xjn4YEa">
          <DigiForm />
        </Route>
        <Route path="/tHQY3cyrlW">
          <TharForm />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
