import {useState} from 'react';
import {Button, Form, Alert} from 'react-bootstrap';

// URL: profitableonlinebusinessideas.com/opyjsPT86Q

export default function WattsSellForm(){
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [success, setSuccess] = useState(false);
    const [addy, setAddy] = useState("");
    const [proptype, setProptype] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zip, setZip] = useState("");
    const [beds, setBeds] = useState("");
    const [baths, setBaths] = useState("");
    const [sqft, setSqft] = useState("");
    const [msg, setMsg] = useState("");
    const [movedate, setMovedate] = useState("");
    const [conmethod, setConmethod] = useState("");

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        setSuccess(true);

        const pkg = {
            name: name,
            email: email,
            phone: phone,
            addy: addy,
            proptype: proptype,
            city: city,
            state: state,
            zip: zip,
            beds: beds,
            baths: baths,
            sqft: sqft,
            msg: msg,
            movedate: movedate,
            conmethod: conmethod,
        }
        
        fetch('/wattssellopt', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-type': 'application/json',
        },
        body: JSON.stringify(pkg)
        })
        .then((res) => res.json())
        .then((data) => {
        console.log("Success", data)
        });

        window.open("https://www.pascocountyforsale.com/thank-you.html", "_blank");
    }

    return(
        <div className="container-fluid opt floydsellform">
                <div className="row" id="opt">
                    <div className="col-12" id="formstyler">
                        <Form onSubmit={handleSubmit}>
                            <div className="row" style={{"marginTop":"25px"}}>
                                <div className="col-6 optform">
                                    <h4>CONTACT INFORMATION</h4>
                                </div>
                                <div className="col-6 optform">
                                    <h4>HOME SPECIFICATIONS</h4>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 optform">
                                    <Form.Group>
                                        <Form.Control required type="text" placeholder="Your Name" onChange={(e) => setName(e.target.value)} />
                                    </Form.Group>
                                </div>
                                <div className="col-6 optform">
                                    <Form.Group>
                                        <Form.Control required type="text" placeholder="Property Type" onChange={(e) => setProptype(e.target.value)} />
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 noborder optform">
                                    <Form.Group>
                                        <Form.Control required type="email" placeholder="youremail@provider.com" onChange={(e) => setEmail(e.target.value)} />
                                    </Form.Group>
                                </div>
                                <div className="col-3 optform">
                                    <Form.Group>
                                        <Form.Control required type="text" placeholder="Bedrooms" onChange={(e) => setBeds(e.target.value)} />
                                    </Form.Group>
                                </div>
                                <div className="col-3 optform">
                                    <Form.Group>
                                        <Form.Control required type="text" placeholder="Bathrooms" onChange={(e) => setBaths(e.target.value)} />
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 optform">
                                    <Form.Group>
                                        <Form.Control required type="text" placeholder="Phone: (XXX)XXX-XXXX" onChange={(e) => setPhone(e.target.value)} />
                                    </Form.Group>
                                </div>
                                <div className="col-6 optform">
                                    <Form.Group>
                                        <Form.Control required type="text" placeholder="Square Footage" onChange={(e) => setSqft(e.target.value)} />
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 optform">
                                    <Form.Group>
                                        <Form.Control required type="text" placeholder="Address" onChange={(e) => setAddy(e.target.value)} />
                                    </Form.Group>
                                </div>
                                <div className="col-6 optform">
                                    <Form.Group>
                                        <Form.Control as="textarea" placeholder="Additional Comments" onChange={(e) => setMsg(e.target.value)} />
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-2 optform">
                                    <Form.Group>
                                        <Form.Control required type="text" placeholder="City" onChange={(e) => setCity(e.target.value)} />
                                    </Form.Group>
                                </div>
                                <div className="col-2 optform">
                                    <Form.Group>
                                        <Form.Control required type="text" placeholder="State" onChange={(e) => setState(e.target.value)} />
                                    </Form.Group>
                                </div>
                                <div className="col-2 optform">
                                    <Form.Group>
                                        <Form.Control required type="text" placeholder="Zip Code" onChange={(e) => setZip(e.target.value)} />
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 optform">
                                    <Form.Group>
                                        <Form.Control required type="text" placeholder="Approximate Date of Move" onChange={(e) => setMovedate(e.target.value)} />
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 optform">
                                    <Form.Group>
                                        <Form.Control required type="text" placeholder="Preferred Method of Contact" onChange={(e) => setConmethod(e.target.value)} />
                                    </Form.Group>
                                </div>
                                <div className="col-6 optform">
                                    {!success &&
                                        <Button variant="secondary" type="submit">Get Started Today!</Button>
                                    }
                                    {success &&
                                        <Button variant="success">Success!</Button>
                                    }
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
        </div>
    )
}