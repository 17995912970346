import {useState} from 'react';
import {Button, Form, Alert} from 'react-bootstrap';

// URL: profitableonlinebusinessideas.com/CvOzbYw9yK

export default function UmanSellForm(){
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [success, setSuccess] = useState(false);
    const [addy, setAddy] = useState("");
    const [calltime, setCalltime] = useState("");
    const [date, setDate] = useState("");

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        setSuccess(true);

        const pkg = {
            name: name,
            email: email,
            phone: phone,
            addy: addy,
            calltime: calltime,
            date: date,
        }
        
        fetch('/umansellopt', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-type': 'application/json',
        },
        body: JSON.stringify(pkg)
        })
        .then((res) => res.json())
        .then((data) => {
        console.log("Success", data)
        });

        window.open("https://www.listingagentbeverlyhills.com/thank-you.html", "_blank");
    }

    return(
        <div className="container-fluid opt umansellform">
                <div className="row" id="opt">
                    <div className="col-12" id="formstyler">
                        <Form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-12 optform">
                                    <Form.Group>
                                        <Form.Label>Your Name</Form.Label>
                                        <Form.Control required type="text" onChange={(e) => setName(e.target.value)} />
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 optform">
                                    <Form.Group>
                                        <Form.Label>Phone</Form.Label>
                                        <Form.Control required type="text" onChange={(e) => setPhone(e.target.value)} />
                                    </Form.Group>
                                </div>
                                <div className="col-6 noborder optform">
                                    <Form.Group>
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control required type="email" onChange={(e) => setEmail(e.target.value)} />
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 optform">
                                    <Form.Group>
                                        <Form.Label>Address (optional)</Form.Label>
                                        <Form.Control type="text" onChange={(e) => setAddy(e.target.value)} />
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 optform">
                                    <Form.Group>
                                        <Form.Label>Best Time to Call (optional)</Form.Label>
                                        <Form.Control as="select" onChange={(e) => setCalltime(e.target.value)}>
                                            <option value="none">Select One...</option>
                                            <option value="Anytime">Anytime</option>
                                            <option value="Morning">In the morning</option>
                                            <option value="Afternoon">In the afternoon</option>
                                            <option value="Evening">In the evening</option>
                                        </Form.Control>
                                    </Form.Group>
                                </div>
                                <div className="col-6 optform">
                                    <Form.Group>
                                        <Form.Label>When do you plan to sell?</Form.Label>
                                        <Form.Control required as="select" onChange={(e) => setDate(e.target.value)}>
                                            <option value="none">Select One...</option>
                                            <option value="Now">Now</option>
                                            <option value="4-8 Months">Four to Eight Months</option>
                                            <option value="8-12 Months">Eight to Twelve Months</option>
                                            <option value="2 Years">Next Two Years</option>
                                        </Form.Control>
                                    </Form.Group>
                                </div>
                            </div>
                            {!success &&
                                <div className="col-6 offset-3">
                                    <Button variant="secondary" type="submit">FIND THE VALUE OF YOUR HOME</Button>
                                </div>
                            }
                            {success &&
                                <div className="col-6 offset-3">
                                    <Button variant="success">SUCCESS!</Button>
                                </div>
                            }
                        </Form>
                    </div>
                </div>
        </div>
    )
}